.social-icons {
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
  z-index: 2;
}

.social-icons ul {
  margin-top: 2rem;
  width: 100%;
  text-align: center;
}

.social-icons ul>li {
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-block;
}

.social-icons ul>li>a {
  display: block;
  color: white;
  background-color: #4c75a3;
  border-radius: 100%;
  font-size: 2rem;
  line-height: 4rem;
  height: 4rem;
  width: 4rem;
  position: relative;
  z-index: 1;
}

.social-icons ul li a .fab {
  position: relative;
  transition: 0.5s;
  z-index: 2;
}

.social-icons ul li a:hover .fab {
  transform: rotateY(360deg);
  color: #fff;
}

.social-icons ul>li>a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: 0.5s;
  border-radius: 50%;
  border: 0px solid #fff;
  z-index: 3;
}

.social-icons ul li a:hover:before {
  right: 100%;
  background: #faa;
  z-index: 1;
}

.social-icons ul li:nth-child(1) a {
  background: #55acee;
}

.social-icons ul li:nth-child(2) a {
  background: #3b5999;
}

.social-icons ul li:nth-child(3) a {
  background: #0077B5;
}

.social-icons ul li:nth-child(1) a:hover:before {
  background: #55acee;
}

.social-icons ul li:nth-child(2) a:hover:before {
  background: #3b5999;
}

.social-icons ul li:nth-child(3) a:hover:before {
  background: #0077B5;
}

.social-icons-horizontal {
  right: 0;
  left: 0;
  top: 25px;
  margin: 15px 5px 5px 5px;
  text-align: center;
}

.social-icons-horizontal .list-unstyled-item {
  display: inline-block;
  margin-right: 15px;
}

@media (min-width: 992px) {
  .social-icons {
    margin: 0;
    position: absolute;
    right: 2.5rem;
    bottom: 2rem;
    width: auto;
  }

  .social-icons ul {
    margin-top: 0;
    width: auto;
  }

  .social-icons ul>li {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .social-icons ul>li:last-child {
    margin-bottom: 0;
  }

  .social-icons ul>li>a {
    transition: all 0.2s ease-in-out;
    font-size: 2rem;
    line-height: 4rem;
    height: 4rem;
    width: 4rem;
  }

  .social-icons-horizontal {
    right: 0;
    left: 0;
    top: 20px;
    margin: 25px 5px 5px 5px;
    text-align: center;
  }
}
