@import url('topNav.css');
@import url('homePage.css');
@import url('footer.css');
@import url('pageNotFound.css');

html {
  height: 100%;
  font-size: 16px;
  font-family: 'initial, Source Sans Pro';

}

body {
  height: 100%;
  min-height: 35rem;
  position: relative;
  font-family: 'initital, Source Sans Pro';
  font-weight: 300;
  background: url("../../assets/Logo/Normal/ICON_TEXT_TRANSPARENT_BG.png") #fff no-repeat center center scroll;
}

nav {
  padding: 20px 0 20px 0;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
}

#mainApp {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow:visible;
}

.mainApp {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: visible;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
  opacity: 0.5;
  z-index: -5;
}

.noBackgroundLogo{
  background: #f7f7f7;
  opacity: 1;
}

.topRouterWrapper {
  width: 100%;
  min-height: calc(100vh - 120px);
  position: relative;
  margin-top: 90px;
}

.geeky-navbar-brand {
  padding: 0;
  margin: 0;
}

.bd-sidebar {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  position: relative;
  top:0;
  height: calc(100vh - 2rem);
  border-right: 1px solid rgba(0, 0, 0, .1);
}


.bd-toc {
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  height: calc(100vh - 2rem);
  overflow-y: auto;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem;
  border-left: 1px solid rgba(0, 0, 0, .1);

}

.bd-content {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  position: relative;
}



@media (min-width: 1200px) {
  .bd-sidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 320px;
    flex: 0 1 320px;
  }
}

@media (min-width: 768px) {
  .bd-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 1000;
    height: calc(100vh - 4rem);
  }
}

@media (min-width: 768px) {
  .bd-sidebar {
    border-right: 1px solid rgba(0, 0, 0, .1);
  }

  .white-underline-text {
    text-decoration: underline;
    color: #fff;
  }
}
