.footer {
  padding-top: 5rem;
  padding-bottom: 3rem;
  background-color: #2c3e50;
  color: #fff;
}

.copyright {
  background-color: #1a252f;
}
