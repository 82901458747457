
.slidingSidebar {
    min-width: 250px;
    max-width: 250px;
    background: #eee;
    color: #999;
    padding: 10px 0;
    transition: all 0.3s;
    border-right: 1px solid #c6d0da;
}

.slidingSidebar.active {
    margin-left: -250px;
}

.overlaySidebar {
    width: 250px;
    position: fixed;
    top: 90px;
    left: -250px;
    height: 100vh;
    z-index: 999;
    padding: 10px 0;
    background: #eee;
    color: #999;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.overlaySidebar.active {
    left: 0;
}

#dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #20a8d8;
    color: #f7f7f7;
    position: absolute;
    top: 25px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#dismiss:hover {
    background: #fff;
    color: #20a8d8;
}

.sidebar-overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.sidebar-overlay.active {
    display: block;
    opacity: 1;
}


#sidebar a,
#sidebar a:hover,
#sidebar a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.sideBarwrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    background: #fafafa;
}

#sidebar .fab, #sidebar .fas{
  margin-right: 5px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #f7f7f7;
    display: flex;
}
#sidebar .sidebar-header a{
  margin: auto;
}

#sidebar ul.components {
    padding: 0 0 20px 0;
}

#sidebar ul p {
    color: #666;
    padding: 20px 10px 20px 5px;
    background: #eee;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
}

#sidebar ul li {
  background:#f7f7f7;
  position: relative;
  color: #666;
}

#sidebar ul li a.borderedLink{
  border-bottom: 1px solid #c6d0da;
}

#sidebar ul li a{
    padding: 20px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a.dropdown-toggle{
  position: absolute;
  top: 0;
  right: 0;
}

#sidebar ul li a:hover:not(.preDropDownAnchor),
#sidebar ul li a:hover + a.dropdown-toggle,
#sidebar ul li a:hover + a.dropdown-toggle::after {
  color: #f7f7f7;
  background: #5f6f81;
}

#sidebar ul li a.active,
#sidebar ul li a.active + a.dropdown-toggle,
#sidebar ul li a.active + a.dropdown-toggle::after {
  font-weight: 600;
  color: #20a8d8;
}

#sidebar ul li a.activeLink,
#sidebar ul li a.activeLink + a.dropdown-toggle,
#sidebar ul li a.activeLink + a.dropdown-toggle::after{
  background: #20a8d8 !important;
  color: #f7f7f7;
  font-weight: 500;
}

#sidebar ul li a.dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  border-top: .3em solid ;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  /* transition: easing 0.1s; */
}

#sidebar ul li a.dropdown-toggle.collapsed::after {
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid ;
  /* transition: easing 0.1s; */
}

#sidebar ul  ul  a {
    font-size: 0.95em !important;
    padding-left: 30px !important;
    background: #f7f7f7;
}

#sidebar ul  ul  ul a {
    font-size: 0.95em !important;
    padding-left: 40px !important;
    background: #f7f7f7;
}

.sidebardropdown{
  transition: 0.3s all;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 992px) {
    .sidebar {
        margin-left: -250px;
    }
    .slidingSidebar.active {
        margin-left: 0;
    }
    .slidingSidebar span {
        display: none;
    }
}

.rotate {
  animation: rotation 10s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
