.sidebarToggler{
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.sidebarToggler a{
    padding: 5px 10px;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    cursor: pointer;
    border-color: rgba(0,0,0,.1);
  }

.sidebarToggler a:hover{
  border-color: rgba(0,0,0,.3);
}

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

.contentTopBar{
  margin: 20px 0 5px 0;
}

@media (min-width: 992px) {
  .contentTopBar + hr {
    margin-top: 0;
  }
}
