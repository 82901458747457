#breadCrumb{
  display: inline-block;
  margin-left: 0;
  vertical-align: middle;
}

#breadCrumb nav,
#breadCrumb .breadcrumb{
  padding: 5px 10px 5px 10px !important;
}

#breadCrumb  ol{
  margin-bottom: 0 !important;
}
